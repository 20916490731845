import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ImageHeader } from "@ultralight-io/components"

import { Layout, CoursesSection } from "../components"

export default function ClassesPage ({data}) {
  const title = data.page.title
  const page = data.page.data
  const image = getImage(page.image?.localFile)

  return (
    <Layout classesActive>
      <ImageHeader 
        className="object-cover object-center"
        title="Classes"
        image={<GatsbyImage image={image} alt={page.image.altText} />}
      />
      <div className="py-12 bg-white dark:bg-gray-800">
        <CoursesSection title={title} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    page: wpPage(slug: {eq: "classes"}) {
      title
      data: acfImageHeader {
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
